import { render, staticRenderFns } from "./ClientProvider.vue?vue&type=template&id=53284474&scoped=true&"
import script from "./ClientProvider.vue?vue&type=script&lang=js&"
export * from "./ClientProvider.vue?vue&type=script&lang=js&"
import style0 from "./ClientProvider.vue?vue&type=style&index=0&id=53284474&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53284474",
  null
  
)

export default component.exports