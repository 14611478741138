<template>
  <div>

    <!--Progress-->
    <v-dialog
      v-model="progress"
      max-width="290"
      persistent
      hide-overlay
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div v-if="progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' +
      clientsStore.client.d_nameFirst"></title-bar>

      <!--Function Bar-->
      <client-function-bar></client-function-bar>

      <v-container fluid class="pa-0">
        <v-row dense>

          <!--Client Provider-->
          <v-col cols="12" sm="12">
            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-school</v-icon>
                <v-toolbar-title class="pl-2">Provider</v-toolbar-title>
              </v-toolbar>
              <v-card-text>

                <v-list three-line>
                  <template v-for="(item, index) in items">
                    <v-list-item
                      :key="item.id"
                      @click=""
                      class="my-1"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold light-blue--text text--darken-4">{{item
                          .date}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text><span class="text-body-1">{{item.status}}</span></v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-list>

              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'ClientApplications',
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
    }),
  },
  components: {
	  TitleBar,
	  ClientFunctionBar,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
	    progress: true,
      client: {
        id: 1,
        uuid: 1234567,
        name: 'Facility Number 1',
        license: '123456',
        type: 'Center',
        rating: '5 Star',
        address: '1010 Main Street',
        phone: '999-999-9999',
        fax: '888-888-8888',
        website: 'www.test.com',
        county: 'Durham',
        status: 'Active',
        ratingDate: '8/24/2017',
      },
      notes: [
        {
          id: 1,
          type: 'A new note',
          date: '4/21/2019',
        },
      ],
	    items: [
		    {
			    id: 1,
			    date: '07/19/2019',
			    status: 'Active',
		    },
	    ],
    };
  },
  methods: {
	  async initialize() {
		  await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);
		  this.progress = false;
	  },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
